import { FrameworkConfiguration, PLATFORM } from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([
    PLATFORM.moduleName('./address-inline'),
    PLATFORM.moduleName('./date-format'),
    PLATFORM.moduleName('./date-time-format'),
    PLATFORM.moduleName('./decimals'),
    PLATFORM.moduleName('./round-number'),
    PLATFORM.moduleName('./empty-dash'),
    PLATFORM.moduleName('./max-length'),
    PLATFORM.moduleName('./image-id-to-url'),
    PLATFORM.moduleName('./lowercase'),
    PLATFORM.moduleName('./parse-int'),
    PLATFORM.moduleName('./price-format'),
    PLATFORM.moduleName('./price-detail-format'),
    PLATFORM.moduleName('./selectoptions'),
    PLATFORM.moduleName('./sort-object-array'),
    PLATFORM.moduleName('./title-case'),
    PLATFORM.moduleName('./is-queued'),
    PLATFORM.moduleName('./service-station-name'),
    PLATFORM.moduleName('./keys'),
    PLATFORM.moduleName('./minify-list'),
    PLATFORM.moduleName('./name-search'),
    PLATFORM.moduleName('./number-spacer'),
    PLATFORM.moduleName('./ucfirst'),
    PLATFORM.moduleName('./uppercase'),
  ]);
}
