import { json } from "aurelia-fetch-client";
import { LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import * as moment from 'moment';

const logger: Logger = LogManager.getLogger("utility");

export  class Utility {
  areEqual(obj1, obj2, debug?) {
    if ((obj1 && !obj2) || (!obj1 && obj2)) {
      return false;
    }
    if (!obj1 && !obj2) {
      return true;
    }
    
    let changedProps: Array<string> = [];

    Object.keys(obj1).every((key) => {   
      if (typeof obj1[key] == 'object' && obj1) {
        let objectIsEqual = this.areEqual(obj1[key], obj2[key], debug);          

        if(!objectIsEqual) {
          if (debug) logger.debug('FOLLOWING KEY IS NOT EQUAL ON OBJECTS: ' + key, obj1[key], obj2[key]);
          changedProps.push('key: ' + key + ', obj1[key]: ' + obj1[key]  + ', obj2[key]: ' + obj2[key]);  
        }

        return objectIsEqual;
      }
      else if (!(obj2.hasOwnProperty(key) && (obj1[key] == obj2[key]))) {
        if (debug) logger.debug('ORIGINAL OBJECT IS MISSING A KEY: ' + key);        
        changedProps.push('key: ' + key + ', originalObject: ' + obj1[key]  + ', realObject: ' + obj2[key] );
      }

      if (!obj2.hasOwnProperty(key) && (obj1[key] == obj2[key])) {
        if (debug) logger.debug('VALUES ARE NOT EQUAL: ' + key);
        changedProps.push('key: ' + key + ', originalObject: ' + obj1[key]  + ', realObject: ' + obj2[key] );
        return false;
      }

      return true;
    });

    // for debugging
    if (debug && changedProps.length > 0) {
      logger.debug('changedprops', changedProps);
    }

    return changedProps.length == 0;
  }

  public areEqualDateFix(object, dateFixes: Array<any>) {

    let objectToFix = object;
    dateFixes.forEach((dateFix: any) => {
      if (!objectToFix[dateFix.key]) {
        return;
      }
      objectToFix[dateFix.key] = moment.default(objectToFix[dateFix.key]).format(dateFix.format || 'YYYY-MM-DD') as any;
    });

    return objectToFix as any;
  }
}
